var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editor)?_c('div',{staticClass:"rs-advisor-editor mb-2"},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.showLinkUrlOverlay,"opacity":"0.95"}},[_c('div',{staticClass:"text-center"},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"clearable":"","outlined":"","color":"white","label":"Add Link URL","hint":"Enter a valid URL (e.g., https://plangap.com)","persistent-hint":"","type":"url","rules":_vm.urlRules},model:{value:(_vm.editorHref),callback:function ($$v) {_vm.editorHref=$$v},expression:"editorHref"}})],1)],1),_c('div',{staticClass:"mt-4 d-flex justify-center align-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"large":"","outlined":"","color":"error"},on:{"click":function($event){_vm.showLinkUrlOverlay = false}}},[_vm._v("Cancel")]),(_vm.hasHref)?_c('v-btn',{staticClass:"mr-4",attrs:{"large":"","outlined":"","color":"primary"},on:{"click":_vm.handleUnsetLink}},[_vm._v("Remove Link")]):_vm._e(),_c('v-btn',{attrs:{"disabled":!_vm.editorHref || !_vm.valid,"large":"","color":"primary"},on:{"click":_vm.handleSetLink}},[_vm._v("Set Link")])],1)]),_c('v-btn-toggle',{staticClass:"mr-4",attrs:{"dense":"","multiple":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"input-value":_vm.editor.isActive('bold')},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-bold")])],1)]}}],null,false,2268299230)},[_c('span',[_vm._v("Bold")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"input-value":_vm.editor.isActive('italic')},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-italic")])],1)]}}],null,false,2012084097)},[_c('span',[_vm._v("Italic")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"input-value":_vm.editor.isActive('underline')},on:{"click":function($event){_vm.editor.chain().focus().toggleUnderline().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-underline")])],1)]}}],null,false,1582302877)},[_c('span',[_vm._v("Underline")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"input-value":_vm.editor.isActive('link')},on:{"click":function($event){return _vm.handleLinkClick()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,false,2129758273)},[_c('span',[_vm._v(_vm._s(!_vm.editor.isActive("link") ? "Set Link" : "Edit Link"))])])],1),_c('v-btn-toggle',{staticClass:"rs-btn-toggle-no-active",attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){_vm.editor.chain().focus().setHardBreak().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-page-break")])],1)]}}],null,false,77102792)},[_c('span',[_vm._v("Break")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-u-left-top")])],1)]}}],null,false,3859338052)},[_c('span',[_vm._v("Undo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-u-right-top")])],1)]}}],null,false,242882547)},[_c('span',[_vm._v("Redo")])])],1)],1):_vm._e(),_c('editor-content',{attrs:{"editor":_vm.editor}}),(_vm.editor)?_c('small',{staticClass:"character-count",class:{ 'red--text': _vm.characterCountExceeded }},[_vm._v(" "+_vm._s(_vm.editor.storage.characterCount.characters())+"/"+_vm._s(_vm.characterLimit)+" characters ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }