<template>
  <div>
    <div v-if="editor" class="rs-advisor-editor mb-2">
      <v-overlay :absolute="true" :value="showLinkUrlOverlay" opacity="0.95">
        <div class="text-center">
          <v-form v-model="valid">
            <v-text-field
              v-model="editorHref"
              clearable
              outlined
              color="white"
              label="Add Link URL"
              hint="Enter a valid URL (e.g., https://plangap.com)"
              persistent-hint
              type="url"
              :rules="urlRules"
            ></v-text-field>
          </v-form>
        </div>
        <div class="mt-4 d-flex justify-center align-center">
          <v-btn
            large
            outlined
            color="error"
            class="mr-4"
            @click="showLinkUrlOverlay = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="hasHref"
            large
            outlined
            color="primary"
            class="mr-4"
            @click="handleUnsetLink"
            >Remove Link</v-btn
          >
          <v-btn
            :disabled="!editorHref || !valid"
            large
            color="primary"
            @click="handleSetLink"
            >Set Link</v-btn
          >
        </div>
      </v-overlay>

      <v-btn-toggle dense multiple class="mr-4">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :input-value="editor.isActive('bold')"
              @click="editor.chain().focus().toggleBold().run()"
            >
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>
          </template>
          <span>Bold</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :input-value="editor.isActive('italic')"
              @click="editor.chain().focus().toggleItalic().run()"
            >
              <v-icon>mdi-format-italic</v-icon>
            </v-btn>
          </template>
          <span>Italic</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :input-value="editor.isActive('underline')"
              @click="editor.chain().focus().toggleUnderline().run()"
            >
              <v-icon>mdi-format-underline</v-icon>
            </v-btn>
          </template>
          <span>Underline</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :input-value="editor.isActive('link')"
              @click="handleLinkClick()"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <span>{{ !editor.isActive("link") ? "Set Link" : "Edit Link" }}</span>
        </v-tooltip>
      </v-btn-toggle>
      <v-btn-toggle dense class="rs-btn-toggle-no-active">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="editor.chain().focus().setHardBreak().run()"
            >
              <v-icon>mdi-format-page-break</v-icon>
            </v-btn>
          </template>
          <span>Break</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="editor.chain().focus().undo().run()"
            >
              <v-icon>mdi-arrow-u-left-top</v-icon>
            </v-btn>
          </template>
          <span>Undo</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="editor.chain().focus().redo().run()"
            >
              <v-icon>mdi-arrow-u-right-top</v-icon>
            </v-btn>
          </template>
          <span>Redo</span>
        </v-tooltip>
      </v-btn-toggle>
    </div>
    <editor-content :editor="editor" />
    <small
      class="character-count"
      :class="{ 'red--text': characterCountExceeded }"
      v-if="editor"
    >
      {{ editor.storage.characterCount.characters() }}/{{ characterLimit }}
      characters
    </small>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import CharacterCount from "@tiptap/extension-character-count";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";

export default {
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
      editor: null,
      characterLimit: 2000,
      editorHref: null,
      showLinkUrlOverlay: false,
      urlRules: [
        (v) =>
          !v ||
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
            v
          ) ||
          // /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
          //   v)
          "Must be a valid URL and begin with http:// or https:// and end with a valid TLD (e.g., .com, .org)",
      ],
    };
  },
  computed: {
    characterCountExceeded() {
      return (
        this.editor.storage.characterCount.characters() >= this.characterLimit
      );
    },
    hasHref() {
      return this.editor.getAttributes("link").href;
    },
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        Link.configure({
          autolink: false,
          openOnClick: false,
          validate: (href) => /^https?:\/\//.test(href),
        }),
        StarterKit,
        Underline,
        CharacterCount.configure({
          limit: this.characterLimit,
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    handleUnsetLink() {
      this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
      this.editorHref = null;
      this.showLinkUrlOverlay = false;
    },
    handleLinkClick() {
      this.editorHref = this.editor.getAttributes("link").href;
      const setType = this.editor.isActive("link");
      const currentSelectionIsEmpty = this.editor.view.state.selection.empty;
      if (!setType) {
        if (!currentSelectionIsEmpty) this.showLinkUrlOverlay = true;
      } else {
        this.showLinkUrlOverlay = true;
      }
    },
    handleSetLink() {
      const url = this.editorHref;

      // empty
      if (!url || url === "") {
        this.handleUnsetLink();
        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();

      this.showLinkUrlOverlay = false;
    },
  },
};
</script>
<style lang="scss">
.rs-btn-toggle-no-active {
  .theme--light.v-btn.v-btn--has-bg.v-btn--active {
    // to get rid of selected state color from btn-toggle
    &:before {
      opacity: 0;
    }
  }
}

.rs-advisor-editor {
  .v-overlay__content {
    flex: 80%;
    padding: 2rem;
  }
}
</style>
